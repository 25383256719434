<template>
  <div class="main-chart">
    <div
      class="chart"
      id="conversionChart"
      :style="{ height: chartHeight }"
    ></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    countData: {
      type: Object,
      default: () => {
        return {
          clientCount: 0,
          signedCount: 0,
          percentage: 0,
        };
      },
    },
    chartHeight: {
      type: String,
      default: "800px",
    },
  },
  data() {
    return {
      conversionChart: null,
    };
  },
  mounted() {
    this.initChart();
  },
  watch: {
    $route: {
      handler() {
        if (this.conversionChart !== null) {
          this.conversionChart.resize();
        }
      },
    },
    countData: {
      handler() {
        this.randerData();
      },
      deep: true,
    },
  },
  methods: {
    initChart() {
      this.conversionChart = echarts.init(
        document.getElementById("conversionChart"),
        "light"
      );
      let xLabelStyle = {
        fontWeight: "bold",
        fontSize: 18,
        color: "#333",
      };
      const option = {
        tooltip: {
          show: true,
          trigger: "item",
          formatter: function (d) {
            var ins = `${d.data.text}：${d.data.value} 个`;
            return ins;
          },
        },
        xAxis: {
          type: "category",
          data: [
            {
              value: "目标",
              textStyle: xLabelStyle,
            },
            {
              value: "实际",
              textStyle: xLabelStyle,
            },
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "main",
            barMinWidth: 20,
            barMaxWidth: 60,
            data: [
              {
                value: 0,
                itemStyle: {},
              },
              {
                value: 0,
                itemStyle: {
                  color: "#e6a23c",
                },
              },
            ],
            type: "bar",
            showBackground: false,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
        ],
      };
      this.conversionChart.setOption(option);
      window.addEventListener("resize", () => {
        this.conversionChart.resize();
      });
    },
    randerData() {
      this.conversionChart.setOption({
        series: [
          {
            name: "main",
            data: [
              {
                text: '目标',
                value: this.countData.clientCount ,
                itemStyle: {
                  color: "#409eff",
                },
              },
              {
                text: '实际',
                value: this.countData.signedCount,
                itemStyle: {
                  color: "#e6a23c",
                },
              },
            ],
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-chart {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  .chart {
    width: 100%;
    height: 600px;
    color: #7bbafa;
  }
}
</style>