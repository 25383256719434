<template>
  <div class="chart-wrap">
    <FunnelChart
      wrapId="dashboardChart"
      :chartData="chartData"
      :showChartTitle="false"
      :showChartToolbox="false"
      chartHeight="500px"
      @onChartClick="handleChartClick"
    />
  </div>
</template>

<script>
import FunnelChart from "./FunnelChart";
import { GetStatisticsStages } from "@/views/statisticAnalysis/api";
import { lastDayOfMonth, startOfMonth } from "date-fns";
import { ShowApiError } from "@/request/error";
export default {
  props: {
    currentMouth: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    FunnelChart,
  },
  data() {
    return {
      params: { createdAt: [] },
      chartData: [],
    };
  },
  created() {
    this.setDefaultFilterParams();
  },
  watch: {
    currentMouth() {
      this.setDefaultFilterParams();
    },
  },
  methods: {
    setDefaultFilterParams() {
      if (this.currentMouth) {
        this.params.createdAt = [
          startOfMonth(new Date()),
          lastDayOfMonth(new Date()),
        ];
      } else {
        this.params.createdAt = [];
      }
      this.getChartData();
    },
    getChartData() {
      GetStatisticsStages(this.params)
        .then((res) => {
          this.chartData = res.data;
        })
        .catch((err) => ShowApiError("获取漏斗图数据异常", err));
    },
    handleChartClick(params) {
      this.$router.push({
        name: "CientsList",
        params: {
          ...this.params,
          stageId: params.data.stageId === 1 ? null : [params.data.stageId],
          disabled: false,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-wrap {
  width: 100%;
  height: 100%;
}
</style>