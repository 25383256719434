<template>
  <div class="custom-client-table" v-loading="tableLoading">
    <el-table
      :data="tableData"
      :height="455"
      style="width: 100%; height: 455px"
    >
      <el-table-column prop="star" label="星标" width="50" align="center">
        <template slot-scope="scope">
          <i
            class="el-icon-star-off"
            v-if="!scope.row.star"
            style="font-size: 20px; cursor: pointer"
          ></i>
          <i
            class="el-icon-star-on text-start"
            v-if="scope.row.star"
            style="font-size: 24px; cursor: pointer"
          ></i>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="客户联系人">
        <template slot-scope="scope">
          <span class="text-nav" @click="onShowDetail(scope.row)">{{
            scope.row.name
          }}</span></template
        >
      </el-table-column>
      <el-table-column
        prop="stageName"
        label="客户阶段"
        align="left"
        fixed="right"
        width="120"
      >
      </el-table-column>
    </el-table>
    <div class="pagenation">
      <el-pagination
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :current-page="page + 1"
        :page-size="size"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { GetClientsList } from "@/views/client/api";
import { checkPermission } from "@/utils/auth";
export default {
  props: {
    stageIds: {
      type: [String, Number, Array],
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      tableLoading: false,
      page: 0,
      size: 20,
      total: 20,
      tableData: [],
      orderProp: "",
      orderDirection: "desc",
    };
  },
  computed:{
    userInfo(){
      return this.$store.getters.userInfo
    }
  },
  created() {
    this.getTableData();
  },
  watch: {
    stageIds: {
      handler() {
        this.$setStorage(`dashboard_client_stage`, this.stageIds);
        this.onRefresh();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    checkPermission,
    onRefresh() {
      this.page = 0;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    getTableData() {
      this.tableLoading = true;
      GetClientsList({
        page: this.page,
        size: this.size,
        sort: `${this.orderProp},${this.orderDirection}`,
        stageId: this.stageIds,
        'starByUsers.id': this.userInfo.id ? this.userInfo.id : null
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          console.log("err", err);
        });
    },
    onShowDetail(row) {
      this.$router.push({
        name: "CientsDetail",
        params: {
          id: row.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-client-table {
  margin: 0;
  box-shadow: none;
  padding: 0 10px;
  .pagenation {
    border-top: 1px solid #f1f1f1;
    text-align: right;
    padding: 5px;
  }
}
</style>