<template>
  <div class="ranking-dashboard" v-loading="loading">
    <div class="top3">
      <p class="section-title">前三</p>
      <RankTop3 :rankData="top3RankList" style="font-size: 10px" />
    </div>
    <div class="ranking-list">
      <p class="section-title">前十</p>
      <RankList :rankData="rankList" style="font-size: 10px" />
    </div>
  </div>
</template>

<script>
import RankTop3 from "@/views/statisticAnalysis/rankings/components/RankTop3";
import RankList from "@/views/statisticAnalysis/rankings/components/RankList";
import { GetStatisticsSignRangking } from "@/views/statisticAnalysis/api";
import { lastDayOfMonth, startOfMonth } from "date-fns";
export default {
  props: {
    currentMouth: {
      type: Boolean,
      default: true,
    },
    self: {
      type: Boolean,
      default: false,
    },
    selfDepartment: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RankTop3,
    RankList,
  },
  data() {
    return {
      loading: false,
      params: {
        createdAt: [],
        ownerId: [],
        departmentId: [],
      },
      top3RankList: [],
      rankList: [],
    };
  },
  computed: {
    useInfo() {
      return this.$store.getters.userInfo;
    },
  },
  created() {
    this.params.createdAt = [
      startOfMonth(new Date()),
      lastDayOfMonth(new Date()),
    ];
    this.getRankList();
  },
  watch: {
    currentMouth() {
      if (this.currentMouth) {
        this.params.createdAt = [
          startOfMonth(new Date()),
          lastDayOfMonth(new Date()),
        ];
      } else {
        this.params.createdAt = [];
      }
      this.getRankList();
    },
    self() {
      if (this.self) {
        if (this.useInfo !== {}) {
          this.params.ownerId = [this.useInfo.id];
        }
      } else {
        this.params.ownerId = [];
      }
      this.getRankList();
    },
    selfDepartment() {
      if (this.selfDepartment) {
        if (this.useInfo !== {}) {
          this.params.departmentId = [this.useInfo.departmentId];
        }
      } else {
        this.params.departmentId = [];
      }
      this.getRankList();
    },
  },
  methods: {
    getRankList() {
      this.loading = true;
      GetStatisticsSignRangking(this.params)
        .then((res) => {
          if (res.data.length > 0) {
            this.rankList = res.data.slice(0, 10);
            // 截取前三名
            this.top3RankList = res.data.slice(0, 3);
          } else {
            this.rankList = [];
            this.top3RankList = [];
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ranking-dashboard {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: #989898;
  overflow-x: hidden;
  overflow-y: auto;
}
.section-title {
  box-sizing: border-box;
  padding: 10px;
  font-size: 15px;
}
.top3 {
  box-sizing: border-box;
  padding: 10px;
}
.ranking-list {
  box-sizing: border-box;
  padding: 10px;
}
</style>