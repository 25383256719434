<template>
  <div class="sign-count-wrap" v-loading="loading">
    <KpiChartBar :countData="countData" chartHeight="500px" />
  </div>
</template>

<script>
import { GetKpiAll } from "@/views/kpi/api";
import { isEmprty } from "@/utils/validate";
import { format } from "date-fns";
import KpiChartBar from "@/views/statisticAnalysis/kpiAnalysis/components/KpiChartBar";
export default {
  props: {
    currentMouth: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    KpiChartBar,
  },
  data() {
    return {
      loading: false,
      params: { createdAt: [], examineeId: null },
      countData: {
        clientCount: 0,
        signedCount: 0,
        percentage: 0,
      },
    };
  },
  computed: {
    useInfo() {
      return this.$store.getters.userInfo;
    },
  },
  watch: {
    currentMouth: {
      handler() {
        if (this.currentMouth) {
          this.params.yearMonth = format(new Date(), "yyyy-MM");
        } else {
          this.params.yearMonth = "";
        }
        this.params.examineeId = this.useInfo.id ? this.useInfo.id : null;
        this.getClientSignCount();
      },
      immediate: true,
    },
  },
  methods: {
    isEmprty,
    getClientSignCount() {
      this.loading = true;
      GetKpiAll(this.params)
        .then((res) => {
          if(res.data.length <= 0){
            this.countData.clientCount = 0;
            this.countData.signedCount = 0;
            this.countData.percentage = 0;
          }
          if (res.data.length === 1) {
            let { requestSignCount, actualSignCount, signRate } = res.data[0];
            this.countData.clientCount = requestSignCount;
            this.countData.signedCount = actualSignCount;
            this.countData.percentage = signRate *100;
          }
          if (res.data.length > 1) {
            let clientCount = 0;
            let signedCount = 0;
            let percentage = 0;
            res.data.forEach(item => {
              clientCount += item.requestSignCount;
              signedCount += item.actualSignCount;
            });
            percentage = clientCount > 0 ? ((signedCount / clientCount).toFixed(2))*100 : 0
            this.countData.clientCount = clientCount;
            this.countData.signedCount = signedCount;
            this.countData.percentage = percentage;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-count-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
</style>