<template>
  <div class="home">
    <section class="base-page-section base-shadow">
      <div class="flex-wrap">
        <div class="avatar-wrap">
          <img src="../../assets/mini-logo.png" alt="" class="avatar-img" />
          <!-- <i class="el-icon-user"></i> -->
        </div>
        <div class="user-content">
          <div class="user-name">{{ timeStatus }} , {{ userInfo.name }}</div>
          <div class="user-desc">
            <el-tag>{{ userInfo.departmentName }}</el-tag>
          </div>
        </div>
      </div>
    </section>
    <section class="base-wrap">
      <el-row :gutter="20">
        <el-col :sm="24" :md="12">
          <el-card class="box-card">
            <div slot="header" class="flex-container">
              <div class="card-title">招商漏斗图</div>
              <div class="card-control">
                <el-checkbox
                  label="本月"
                  size="small"
                  border
                  v-model="showMounthMerchantFunnel"
                  class="mt-r"
                ></el-checkbox>
                <el-button
                  type="text"
                  class="mt-l"
                  @click="$router.push('/funnel/analysis')"
                  >查看更多</el-button
                >
              </div>
            </div>
            <MerchantFunnelChart :currentMouth="showMounthMerchantFunnel" />
          </el-card>
        </el-col>
        <el-col :sm="24" :md="12">
          <el-card class="box-card">
            <div slot="header" class="flex-container">
              <div class="card-title">星标客户</div>
              <div class="card-control">
                <BasicSelect
                  style="max-width: 200px; margin-right: 5px"
                  v-model="clientStageIds"
                  size="small"
                  :allOptions="clientStageOptions"
                />
                <el-button size="small" class="float-r" @click="navToAddClient"
                  >新增客户</el-button
                >
                <el-button
                  type="text"
                  class="mt-l"
                  @click="$router.push('/clients/list')"
                  >查看更多</el-button
                >
              </div>
            </div>
            <ClientList :stageIds="clientStageIds" />
          </el-card>
        </el-col>
        <el-col :sm="24" :md="12">
          <el-card class="box-card">
            <div slot="header" class="flex-container">
              <div class="card-title">招商排行榜</div>
              <div class="card-control">
                <el-checkbox
                  label="本月"
                  size="small"
                  border
                  v-model="showMounthRanking"
                  class="mt-r"
                ></el-checkbox>
                <el-checkbox
                  label="只看自己"
                  size="small"
                  border
                  v-model="showSelfRanking"
                  class="mt-r"
                ></el-checkbox>
                <el-checkbox
                  label="只看部门"
                  size="small"
                  border
                  v-model="showDepartmentRanking"
                ></el-checkbox>
                <el-button
                  type="text"
                  class="mt-l"
                  @click="$router.push('/rankings')"
                  >查看更多</el-button
                >
              </div>
            </div>
            <Ranking
              :currentMouth="showMounthRanking"
              :self="showSelfRanking"
              :selfDepartment="showDepartmentRanking"
            />
          </el-card>
        </el-col>
        <el-col :sm="24" :md="12">
          <el-card class="box-card">
            <div slot="header" class="flex-container">
              <div class="card-title">目标完成情况</div>
              <div class="card-control">
                <el-checkbox
                  label="本月"
                  size="small"
                  border
                  v-model="showMounthSignCount"
                  class="mt-r"
                ></el-checkbox>
                <el-button
                  type="text"
                  class="mt-l"
                  @click="$router.push('/conversion/analysis')"
                  >查看更多</el-button
                >
              </div>
            </div>
            <div class="count-container">
              <!-- <div class="count-white-block hidden-sm-and-down"></div> -->
              <ClientSignCount :currentMouth="showMounthSignCount" />
            </div>
          </el-card>
        </el-col>
      </el-row>
    </section>
  </div>
</template>

<script>
import { timeFix } from "@/utils/common";
import ClientList from "./components/ClientList";
import ClientSignCount from "./components/ClientSignCount";
import Ranking from "./components/Ranking";
import MerchantFunnelChart from "./components/MerchantFunnelChart";
import BasicSelect from "@/components/BasicSelect";
import { GetClientStagesAll } from "@/views/client/clientStages/api";
import ranking from "./mixins/ranking";
export default {
  components: {
    BasicSelect,
    ClientSignCount,
    ClientList,
    Ranking,
    MerchantFunnelChart,
  },
  mixins: [ranking],
  data() {
    return {
      showMounthSignCount: true,
      showMounthMerchantFunnel: true,
      timeStatus: "",
      clientStageIds: [],
      clientStageOptions: [],
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  created() {
    this.getLocalRecords();
    this.getStageOptions();
    this.timeStatus = timeFix();
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.name === "Home") {
          this.getLocalRecords();
        }
      },
      deep: true,
    },
  },
  methods: {
    getLocalRecords() {
      if (
        this.$store.getters.userInfo !== {} &&
        this.$store.getters.userInfo.username !== ""
      ) {
        this.clientStageIds = this.$getStorage(
          `${this.$store.getters.userInfo.username}_client_stage`
        );
      }
    },
    navToAddClient() {
      this.$router.push({
        name: "ClientsForm",
        params: {
          id: null,
          type: "CLIENT",
        },
      });
    },
    getStageOptions() {
      GetClientStagesAll().then((res) => {
        this.clientStageOptions = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.base-wrap {
  box-sizing: border-box;
  margin: 20px;
  margin-bottom: 0;
  overflow: hidden;
}
.box-card {
  margin-bottom: 20px;
  ::v-deep {
    .el-card__header {
      height: 57px;
      padding: 0;
      .flex-container {
        box-sizing: border-box;
        width: 100%;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        overflow: hidden;
        & .card-title {
          font-size: 16px;
          flex: 1;
          min-width: 130px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        & .card-control {
          flex: auto;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
    .el-card__body {
      padding: 0;
      height: 500px;
    }
  }
  .count-container {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    .count-white-block {
      height: 50px;
    }
  }
}
.process-block {
  text-align: center;
  margin: 20px auto;
}
.base-page-section {
  padding: 20px;
  margin: 20px;
}
.home {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .avatar-wrap {
    flex: 80px 0 0;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    font-size: 30px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    .avatar-img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .user-content {
    box-sizing: border-box;
    border-left: 1px solid #f1f1f1;
    margin-left: 15px;
    padding-left: 15px;
    flex: 1;
    text-align: left;
    overflow: hidden;
    .user-name {
      box-sizing: border-box;
      font-size: 20px;
      color: #000;
      margin-top: 10px;
    }
    .user-desc {
      font-size: 14px;
      color: #989898;
      margin-top: 12px;
    }
  }
  .flex-wrap {
    display: flex;
    overflow: hidden;
  }
}
</style>